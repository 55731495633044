
@keyframes FadeInUp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}



.res-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-justify: center;
}

.header {
    margin-bottom: 0 !important;
    display: flex;
    justify-content: center;
    font-family: 'Kdam Thmor Pro', sans-serif;
}

.block {
    width: 70%;
    padding-right: 10%;
    padding-left: 10%;
    transition: all .5s ease 0s; 
}


.block h2 {
    margin-bottom: 0px;
    font-family: 'Kdam Thmor Pro', sans-serif;
}

.block h4 {
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: 'Kdam Thmor Pro', sans-serif;
}

.timeframe {
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: 'Karla';
}

.description {
    margin-top: 5px;
    margin-bottom: 30px;
    font-family: 'Karla';
}

.resdown {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    font-family: 'Karla';
}

#volunteering ul{
    margin-top: 2%;
    padding-left: 5%;
    font-family: 'Karla';
}


#skills li {
   padding-bottom: 5%;
   margin-left: 10%;
}

#skills ul {
    display: flex;
    flex-direction: row;
    list-style-type: square;
    white-space: wrap;
    flex-wrap: wrap;
    margin-top: 2%;
    width: 70%;
    padding-left: 0;
}

.res-row {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2%;
    animation: 1s ease-in FadeInUp;  
}

.resume {
    display: flex;
    width: 60%;
    border-style: solid;
    max-width: 600px;
}

.resume-page {
    width: 100%;
    object-position: 0 !important; 
}

/*add in later*/
.react-pdf__Page__textContent {
    display: none !important;
    width: 97% !important;
    height: 90% !important;
}

.react-pdf__Page__canvas {
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important
}

.plain-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: 1s ease-in FadeInUp;  
}

.row p {
    width: 70%;
    line-height: 2;
    font-size: 100%;
    animation: 1s ease-in FadeInUp;  
}

@media screen and (max-width: 600px) {
    .resume {
        width: 80vw !important;
        border-width: .1vw;
    }

    #skills li {
        width: 15vw;
        margin-left: 1vw;
    }

    #skills ul {
        flex-direction: column;
        padding-left: 3%;
    }

}