#outbox {
    display: flex;
    justify-content: center;
    background: rgb(199, 198, 198);
    height: 100%;
    position: relative;
}

#inbox {
    height: 50%;
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-style: solid;
    border-width: 0;
    border-bottom-width: 1px;
    
}

.design {
    width: 100%;
    height: 10vh;
}

.mid-square {
    height: 10px;
    width: 10%
}