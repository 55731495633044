/*Up animation tentative*/
@keyframes FadeInUp {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#first-part {
    margin-top: 0;
}

.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    width: 100%;
    height: 100%;
}


.partition h1 {
    margin-bottom: 1%;
    font-family: 'Kdam Thmor Pro', sans-serif;
}

#second-part {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    width: 100vw;
}

#second-part h1{
    margin-right: 5%;
    font-size: 2em;
    
}

.paragraph {
    line-height: 2.5;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 0;
    width: 70%;
    font-size: 1em;
    font-family: 'Karla';
    border-bottom: solid;
    border-width: .2em;
    border-color: rgb(86, 199, 255);;
}
 
.partition {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-top: 5%;
   font-size: 100%;
   width: 70%;
   height: 100%;
   box-shadow: 10px, 10px;
   animation: .7s ease-in FadeInUp;
}


.row {
    display: flex;
    justify-content: center;
}


#gal-text-row {
    object-fit: contain;
    display: flex;
}


.secondExp {
    margin-right: 0% !important
}

#experience {
    display: grid;
    width: 20%;
    padding: 0;
    list-style: none;
    margin: 0;
    margin-right: 2%;
}

#experience h2 {
    margin: 0;
    margin-left: 7%;
}

#experience li {
    margin-top: 20%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 6em;
    width: 100%;
    object-position: 0;
    font-family: 'Karla';
    font-size: 1.2em;
    background: rgb(86, 199, 255);
    border-radius: .5em
}

#experience li img {
    height: 70%;
}

.gallery {
    width: 70% !important
}

@media screen and (max-width: 1400px) {
    .gallery {
        width: 90%
    }

    .paragraph {
        font-size: 1.5vh;
        width: 75vw;
        margin-right: 5%;
        margin-left: 5%;
        padding-left: 1%;
        padding-right: 1%;
    }

    .partition h1 {
        font-size: 2vw;
    }

    .partition {
        font-size: 1.5vh;
        align-items: center;
    }

    #experience li {
        width: 100%;
    }

    #experience h2 {
        font-size: 4vw !important;
    }

    #second-part {
        flex-direction: column;
    }

    #experience {
        margin-right: 0;
        display: flex;
        width: 50vw;
        flex-direction: column;
        justify-content: center;
    }

    #experience li img {
        height: 80%;
    }

    #second-part h1 {
        flex-direction: column;
        font-size: 2em;
        margin-right: 0;
        margin-bottom: 0
    }
}
