.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.first-half {
    width: 100%;
    background: white;
}

.header {
    margin-bottom: 1%;
    font-family: 'Kdam Thmor Pro', sans-serif;
}

#project-second{
    justify-content: center;
    font-family: 'Karla'
}

#project-second p {
    margin-top: 0;
    width: 60%;
    text-align: center;
    line-height: 3;
}

.second-header {
    font-family: 'Kdam Thmor Pro', sans-serif;
}
@media screen and (max-width: 600px) {
    /*fix border stuff*/

    .header{
        font-size: 6vw;
    }

    #project-second p{
        font-size: 3vw !important;
    }

    .second-header h1 {
        font-size: 6vw;
    }

    .project-paragraph {
        font-size: 1vh;
    }
}

