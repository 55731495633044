
@keyframes rotateBurger {
    0% {opacity: 1; rotate: 0;}
    100% {opacity: 0; rotate: 75deg}
}

@keyframes slideIn {
    from {transform: translateY(-10%); opacity: 0;}
    to{transform: translateY(0); opacity: 1;}
}

@keyframes wait {
    from {opacity: 0;}
    to {opacity: 0;}
}


.navbar {
    display: -webkit-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;
    position: fixed ;
    top: 0px;
    background: rgb(86, 199, 255);
    white-space: nowrap;
    border-bottom-color: black;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    box-shadow: none;
    transition: all .3s ease 0s;
    z-index: 5;
}

/*fix delayed sliding on resize*/
.link {
    height: 10px;
    font-family: 'Kdam Thmor Pro', sans-serif;
    text-decoration: none;
    color: rgb(255, 255, 255);
    margin-right: 3vw;
    transition: all .3s ease 0s
}


.link:hover {
    height: 10px;
    font-family: 'Kdam Thmor Pro', sans-serif;
    text-decoration: none;
    color: rgb(73, 72, 72);
    transition: all .3s ease 0s;
}

.navLinks {
    list-style: none;
    position: absolute;
    padding-left: 0px;
    margin-left: 0px;
    top: 1px;
}


.navLinks li {
    display: inline-block;
}


.logo {
    height: 40px;
    width: 40px;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 2px;
    margin-left: 3vw;
    margin-right: 2vw;
}

.logo img:hover {
    transform: scale(1.1);
    transition: .5s ease 0s;
    box-shadow: 2px 2px 2px black;
}

.logo img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    transition: transform .5s ease 0s
}

.icon {
    position: relative;
    top: 4px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    transition: all .3s ease 0s;
    margin-right: 3vw;
}

.icon:hover {
    transition: .5s ease 0;
    transform: scale(1.1);
    box-shadow: 1px 1px 1px black;
}

.hidden {
    display: none;
    transition: all 1s ease 1s;
}

.mobile-menu {
    height: 40px;
    width: 40px;
    display: none;
    position: absolute;
    right: 0px;
    margin-right: 2vw;
    align-items: center;
    appearance: none;
}

.burger-toggle {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 25%;
    border-style: none;
    animation: rotateBurger .2s ease 0 ;
}

/*make this look nice*/
.lines {
    display: -webkit-flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 40px;
    width: 100%;
}

.line {
    position: relative;
    height: 5%;
    width: 100%;
    margin-top: 30%;
    height: 6%;
    background: white;
}

.lines:checked{
    animation: rotateBurger 1s ease 0s ;
    
}

#icon:hover {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    transition: all .3s ease 0s;
}

#git {
    transition: all .3s ease 0s;
    background: #FFF;
}

#scrolled {
    box-shadow: 0 5px 5px black;
    transition: all .3s ease 0s;
}

.uncollapse {
    display: none;
    position: absolute;
    top: 62px;
    left: -.35vw;
    list-style: none;
    transition: all 1s ease 0s;
}

#b1 {
    animation: slideIn .3s ease 0s;
}

#b2 {
    animation: wait .3s ease 0s, slideIn .3s ease .3s;
}

#b3 {
    animation: wait .6s ease 0s, slideIn .3s ease .6s;
}

#b4 {
    animation: wait .9s ease 0s, slideIn .3s ease .9s;
}

#b5 {
    animation: wait 1.2s ease 0s, slideIn .3s ease 1.2s;
}

.drop-links {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.drop-links li {
    height: 40px;
    background: white;
    border-style: solid;
    border-top: none;
    border-width: 2px;
    width: 100%;
    text-align: center;
}

.drop-links li a {
    position: relative;
    top: 8px;
    color: rgb(73, 72, 72);
}

.drop-links li a:hover {
    color: rgb(168, 165, 165);
}

/*shrink gallery buttons*/
@media screen and (max-width: 600px) {
    .navLinks {
        display: none;
    }
    
    .burger-toggle b {
        font-size: 25px;
    }

    .mobile-menu {
        display: -webkit-flex
    }

    .uncollapse {
        display: -webkit-flex;
        justify-content: center;
        width: 100vw;
    }
}

